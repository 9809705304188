<template>
  <div class="page-content">
    <div class="container">
      <NavBtn :list="navList" />
      <div class="panel-request">
        <div class="panel-request__num">Номер заявки <span>№1256</span></div>
        <div class="panel-request__block">
          <BlockPerson
            class="box-border-bottom"
            :person="{
              name: 'Остап Паляниця',
              image: require('@img/temp/avatar-2.jpg'),
              date: '24 декабря 2020, 19:53',
              status: 'Назначено администратором',
            }"
          />
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text"><b>Медицинская помощь:</b></div>
              <div class="ic-ell__text">Массаж</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text"><b>1,3 км</b> до места оказания услуги</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.5 0C2.436 0 0 2.48 0 5.6 0 9.76 5.5 16 5.5 16S11 9.76 11 5.6C11 2.48 8.564 0 5.5 0zm0 7.6c-1.1 0-1.964-.88-1.964-2s.864-2 1.964-2 1.964.88 1.964 2-.864 2-1.964 2z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text"><b>Адрес:</b></div>
              <div class="ic-ell__text">Lorem ipsum dolor sit amet</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="ic-ell">
              <div class="ic-ell__icon">
                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.421 8.333v.556C10.421 9.5 9.9 10 9.263 10H1.158C.52 10 0 9.5 0 8.889V1.11C0 .5.521 0 1.158 0h8.105c.637 0 1.158.5 1.158 1.111v.556h-5.21c-.637 0-1.158.5-1.158 1.11v4.445c0 .611.52 1.111 1.158 1.111h5.21zm-5.21-1.11H11V2.777H5.21v4.444zm2.315-1.39c-.463 0-.868-.389-.868-.833 0-.444.405-.833.868-.833.463 0 .869.389.869.833 0 .444-.406.833-.869.833z" fill="#0C72D6"/></svg>
              </div>
              <div class="ic-ell__text"><b>Стоимость:</b> 500 руб./день</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__descr">
              <div class="panel-request__text">Краткое описание заявки, которое заполнил пользователь, оставивший заявку.</div>
            </div>
          </div>
        </div>
        <div class="panel-request__block">
          <h3 class="panel-request__title">Нуждается в помощи</h3>
          <div class="panel-request__item">
            <BlockPerson
              class="block-person--lg"
              :person="{
                name: 'Томас Квитка',
                image: require('@img/temp/avatar-1.jpg'),
                gender: 'Мужской',
              }"
            />
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Дата рождения</div>
              <div class="panel-request__field-value">12/5/1978</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Вес</div>
              <div class="panel-request__field-value">90 кг</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Диагноз</div>
              <div class="panel-request__field-value">Маниакально-депрессивный психоз</div>
            </div>
          </div>
          <div class="panel-request__item">
            <div class="panel-request__field">
              <div class="panel-request__field-prop">Адрес</div>
              <div class="panel-request__field-value">г.Москва, ул.Н.Островского 198, кв 456</div>
            </div>
          </div>

          <div class="mt-3 mb-3 es-services">
            <div class="panel-request__item">
              <div class="panel-request__field">
                <div class="panel-request__field-prop">Услуга</div>
                <div class="panel-request__field-value">
                  Медицинская помощь: Массаж
                </div>
              </div>
            </div>
            <div class="es-services__item">
              <div class="es-services__title">
                Начало
              </div>
              <div class="es-services__desc">
                <span>16 апр. 2020 г.</span>
                <span>16:00</span>
              </div>
            </div>
            <div class="es-services__item">
              <div class="es-services__title">
                Конец
              </div>
              <div class="es-services__desc">
                <span>30 апр. 2020 г. </span>
                <span>16:00</span>
              </div>
            </div>
            <div class="es-services__item">
              <div class="es-services__title">
                Количество часов в день
              </div>
              <div class="es-services__desc">
                <span>01:00</span>
              </div>
            </div>
            <div class="es-services__item">
              <div class="es-services__title">
                Регулярность
              </div>
              <div class="es-services__desc es-services__desc--black">
                <span>Пн Ср Пт</span>
              </div>
            </div>
          </div>
          <ServiceTable />
        </div>
        <div class="panel-request__block">
          <h3 class="panel-request__title">Выбрать исполнителя</h3>
          <div class="executor-list">
            <template v-for="item in 10">
              <div class="executor-list__ell" :key="item.index">
                <CardExecutor :name="executor.name" :role="executor.role" :photo="executor.photo" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="page-content__btn-gp">
        <button type="button" class="btn btn--prime">Назначить исполнителя</button>
      </div>
    </div>
    <div class="page-content__footer">
      <v-dialog v-model="dialogSupport">
        <template v-slot:activator="{ on, attrs }">
          <div class="page-content__link" v-on="on" v-bind="attrs">Техническая поддержка</div>
        </template>
        <v-card>
          <div class="btn-close" @click="dialogSupport = false">
            <svg width="12" height="11" viewBox="0 0 12 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.008 9.375a.71.71 0 000 .992.71.71 0 00.992 0L6 6.36l4.008 4.008a.7.7 0 00.984 0 .696.696 0 000-.992l-4-4.008 4-4a.696.696 0 000-.992.7.7 0 00-.984 0L6 4.383 2 .375a.71.71 0 00-.992 0 .71.71 0 000 .992l4.008 4-4.008 4.008z"/></svg>
          </div>
          <ModalSupport />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import NavBtn from '@component/Module/NavBtn';
import BlockPerson from '@component/Module/BlockPerson';
import ServiceTable from '@component/Module/ServiceTable';
import CardExecutor from '@component/Module/CardExecutor';
import ModalSupport from '@component/Modal/ModalSupport';

export default {
  name: 'ActiveApplication',
  components: {
    NavBtn,
    BlockPerson,
    ServiceTable,
    ModalSupport,
    CardExecutor,
  },
  data() {
    return {
      navList: [
        {
          label: 'Заявки в работе',
          name: 'ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS',
          icon: require('@img/icons/app-new-provide.svg'),
        },
        {
          label: 'Отклики',
          name: 'ASSISTANCE_PROVIDE_COMPANY_RESPONSES',
          icon: require('@img/icons/app-responses-provide.svg'),
        },
        {
          label: 'Выполненные',
          name: 'ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATIONS',
          icon: require('@img/icons/app-completed-provide.svg'),
        },
      ],
      executor: {
        name: 'Василий Петров',
        role: 'Указывать специальзацию',
        photo: require('@img/temp/avatar-1.jpg'),
      },
      rateVue: 4,
      dialogSupport: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.es-services {
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.2);
  overflow: hidden;
  padding: 20px 15px;
  border-radius: 8px;
}

.es-services__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.es-services__title {
  font-size: 15px;
}

.es-services__desc {
  font-size: 15px;
  color: #0C76DA;
}

.es-services__desc--black {
  color: #000;
}

// panel-request
.panel-request {
  position: relative;
  margin-bottom: 40px;
  padding: 12px 27px 21px;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  .request-status {
    position: absolute;
    top: 12px;
    right: 30px;
  }
  .block-person {
    padding-top: 10px;
    padding-bottom: 18px;
  }
  .panel-request__num {
    margin-bottom: 20px;
    color: rgba(2, 2, 2, 0.3);
    font-size: 15px;
    span {
      color: #020202;
    }
  }
  .panel-request__title {
    margin-bottom: 19px;
    color: #020202;
    font-size: 17px;
    font-weight: 600;
  }
  .panel-request__value {
    color: #0C76DA;
    font-size: 15px;
  }
  .panel-request__label {
    margin-bottom: 15px;
    color: #000000;
    font-size: 17px;
  }
  .panel-request__text {
    color: rgba(0, 0, 0, 0.4);
    font-size: 17px;
  }
  .panel-request__block {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .panel-request__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(163, 163, 163, 0.3);
    }
  }
  .panel-request__about {
    padding: 20px 0 10px;
  }
  .panel-request__descr {
    padding: 16px 0 10px;
  }
  .ic-ell {
    padding: 16px 0;
  }

  .ic-ell__text {
    margin: 0 5px 0 0;
  }
  .panel-request__field {
    padding: 11px 0;
    .panel-request__field-prop {
      margin-bottom: 6px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 15px;
    }
    .panel-request__field-value {
      color: #000000;
      font-size: 17px;
    }
  }
}

// executor-list
.executor-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  .executor-list__ell {
    width: 20%;
    margin-bottom: 10px;
    padding: 0 10px;
  }
}

</style>
